<template>
  <div class="space-y-2 px-4 py-3">
    <h1 v-if="content.title" class="text-2xl">
      {{ content.title }}
    </h1>
    <h2 v-if="content.subtitle" class="text-2xl text-right">
      {{ content.subtitle }}
    </h2>
  </div>
</template>
<script>
export default {
  friendlyName: 'Page Header',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    }
  }
}
</script>
